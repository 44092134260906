// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-landing-jsx": () => import("../src/pages_/Landing.jsx" /* webpackChunkName: "component---src-pages-landing-jsx" */),
  "component---src-pages-ubications-jsx": () => import("../src/pages_/Ubications.jsx" /* webpackChunkName: "component---src-pages-ubications-jsx" */),
  "component---src-pages-owner-jsx": () => import("../src/pages_/Owner.jsx" /* webpackChunkName: "component---src-pages-owner-jsx" */),
  "component---src-pages-boat-detail-jsx": () => import("../src/pages_/BoatDetail.jsx" /* webpackChunkName: "component---src-pages-boat-detail-jsx" */),
  "component---src-pages-search-jsx": () => import("../src/pages_/Search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-static-list-jsx": () => import("../src/pages_/StaticList.jsx" /* webpackChunkName: "component---src-pages-static-list-jsx" */),
  "component---src-pages-post-jsx": () => import("../src/pages_/Post.jsx" /* webpackChunkName: "component---src-pages-post-jsx" */)
}

